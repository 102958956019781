
import PlateNumber from "@/components/anpr/PlateNumber"
import EventThumbnail from "@/components/gateReport/eventsTable/EventThumbnail"
import EventTimeCell from "@/components/gateReport/eventsTable/EventTimeCell"

export default {
  name: "MobileEventsTableRow",
  components: { PlateNumber, EventThumbnail, EventTimeCell },
  inject: [
    "event",
    "eventTruckType",
    "eventPlateNumber",
    "showLegacyGateReport",
    "visibleHeaders",
  ],
}

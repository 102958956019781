
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"

export default {
  name: "EventTimeCell",
  mixins: [EventsUtils],
  inject: [
    "event",
    "eventType",
    "isAnprBasedProject",
    "projectCameras",
    "eventTypeIconStyle",
  ],
  computed: {
    hasMultipleCameras() {
      return Object.keys(this.projectCameras).length > 1
    },
    eventTime() {
      return this.event?.eventTime
    },
    eventCameraName() {
      return this.projectCameras[this.event?.cameraExid]?.name
    },
    eventLoadState() {
      return this.$t(
        `content.gate_report.load_state_${this.event?.loadState?.toLowerCase()}`
      )
    },
    hasLoadState() {
      return (
        this.event.loadState &&
        this.$permissions.project.has.fullEmptyFiltering()
      )
    },
  },
}


import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
export default {
  data() {
    return {
      height: "100%",
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    tableTextCols() {
      return this.gateReportStore.showAnprBasedGateReport ? 2 : 3
    },
    tableImageCols() {
      return this.gateReportStore.showAnprBasedGateReport ? 2 : 1
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize()
    })
  },
  methods: {
    onResize() {
      const padding = 20
      const tableContainerTopPosition =
        this.$refs.tableContainer?.getBoundingClientRect().top
      const newHeight = window.innerHeight - tableContainerTopPosition
      this.height = `${newHeight - padding}px`
    },
  },
}


import DropDownButton from "@/components/DropDownButton"
import { AiApi } from "@evercam/shared/api/aiApi"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import { useProjectStore } from "@/stores/project"
export default {
  components: { DropDownButton },
  data() {
    return {
      formats: [
        {
          name: "PDF (*.pdf)",
          value: "pdf",
          logo: require("~/assets/img/pdf.png"),
        },
        {
          name: "CSV (*.csv) ",
          value: "csv",
          logo: require("~/assets/img/csv.png"),
        },
      ],
      totalDownloadProgress: 0,
    }
  },
  computed: {
    specialSmWidth() {
      let width = this.$vuetify.breakpoint.width

      return width > 599 && width < 700
    },
    ...mapStores(useGateReportStore, useProjectStore),
  },
  methods: {
    async exportAs({ value }) {
      try {
        this.totalDownloadProgress = 0
        this.gateReportStore.isDownloadingEvents = true
        const fileType = value
        this.$setTimeout(this.requestExportProgress, 1000)
        const response = await this.gateReportStore.requestExport({
          fileType,
        })

        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `Gate-report-events-${this.gateReportStore.selectedDate}.${fileType}`
        )
        document.body.appendChild(link)
        link.click()
        const events = {
          pdf: "export_gate_report_PDF",
          csv: "export_gate_report_CSV",
        }
        if (value in events) {
          this.$analytics.saveEvent(events[value])
        }
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.gate_report.export.download_failed"),
          error,
        })
      } finally {
        this.gateReportStore.isDownloadingEvents = false
      }
    },
    async requestExportProgress() {
      try {
        const projectExid = this.projectStore.selectedProjectExid
        const payload = {
          exportDate: this.gateReportStore.selectedDate,
        }
        const { progress } = await AiApi.gateReport.exportEventsProgress(
          projectExid,
          payload
        )
        this.totalDownloadProgress = progress
        if (
          (progress && progress >= 100) ||
          !this.gateReportStore.isDownloadingEvents
        ) {
          return
        }

        this.$setTimeout(this.requestExportProgress, 1000)
      } catch (error) {
        console.error("error on fetch progress state ....")
      }
    },
  },
}
